import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

import StaffSlider from '../components/sliders/StaffSlider'

const CurrentDev = () => {
  return (
    <Layout>
      <Seo title="Current Dev" description="Gray and Sons Jewelry | Used rolex" noindex={true} />
      <StaffSlider />
    </Layout>
  )
}

export default CurrentDev
