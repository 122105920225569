import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import useCDNImage from '../../hooks/useCDNImage'
import PropTypes from 'prop-types'
import DotSlider from '../../components/sliders/DotSlider'

const StaffSlider = ({ className, eager = false }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiTeamMember(sort: { fields: [priority, name], order: [DESC, ASC] }) {
        members: nodes {
          name
          title
          bio
          image {
            gatsbyImageDataMock
          }
        }
      }
    }
  `)

  // Extract members from the data
  const members = data.allStrapiTeamMember.members

  return (
    <DotSlider className={className}>
      {members.map((member, index) => {
        const processedImage = useCDNImage(member.image) // Use the hook to process the image
        return (
          <div
            key={index}
            className="h-full grid grid-rows-[1fr_auto] group border max-w-[300px] w-full justify-center items-center px-3 rounded-md duration-200 gap-2 py-2 relative z-0"
          >
            <div className="aspect-square w-full max-w-32 mx-auto ">
              <GatsbyImage
                image={processedImage.gatsbyImageData} // Use processed image
                alt={`${member.name} - ${member.title}`}
                className="w-full h-full rounded-full"
                eager={eager}
              />
            </div>
            <>
              <h2 className="font-bold uppercase text-sm line-clamp-1 text-center">
                {member.name}
              </h2>
              <p className="text-sm font-medium text-center text-gray-600 text-wrap h-10 line-clamp-2">
                {member.title}
              </p>
            </>
          </div>
        )
      })}
    </DotSlider>
  )
}

// PropTypes validation
StaffSlider.propTypes = {
  className: PropTypes.string,
  eager: PropTypes.bool,
}

export default StaffSlider
